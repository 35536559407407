@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Frankfurter';
    src: local('Frankfurter'), url(assets/fonts/Frankfurter/Frankfurter-Plain.ttf) format('truetype');
}

@font-face {
    font-family: 'Helvetica';
    src: local('Helvetica'), url(assets/fonts/Helvetica/Helvetica-01.ttf) format('truetype');
}

* { font-family: 'Helvetica'; }